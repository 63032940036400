import React from "react";
import '../App.css';

const FooterStudio = () => {
    return (
      <footer className="bg-primary-gradient">
        <div className="container py-4 py-lg-5">
          <div className="text-muted d-flex justify-content-between align-items-center pt-3">
            <p className="mb-0">
              Copyright ©2024 The Redeemer Church of Christ
            </p>
          </div>
        </div>
      </footer>
    );
}

export default FooterStudio;
