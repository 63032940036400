import React from "react";
import one from '../assets/img/1.jpg'
import two from '../assets/img/2.jpg'
import three from '../assets/img/3.jpg'
import four from '../assets/img/4.jpg'
import five from '../assets/img/5.jpg'
import six from '../assets/img/6.jpg'
import seven from '../assets/img/7.jpg'
import eight from '../assets/img/8.jpg'
import nine from '../assets/img/9.jpg'
import ten from '../assets/img/10.jpg'

const GalleryStudio = () => {
    return(
        <section>
  <div className="container d-flex flex-column align-items-center py-4 py-xl-5">
    <section className="photo-gallery py-4 py-xl-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Gallery</h2>
          </div>
        </div>
        <div
          className="row gx-2 gy-2 row-cols-1 row-cols-md-2 row-cols-xl-3 photos"
          data-bss-baguettebox=""
        >
          <div className="col item">
              <img className="img-fluid" src={one} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={two} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={three} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={four} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={five} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={six} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={seven} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={eight} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={nine} />
          </div>
          <div className="col item">
              <img className="img-fluid" src={ten} />
          </div>
        </div>
      </div>
    </section>
  </div>
</section>

    );
}

export default GalleryStudio;