import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './boots.css'
import Home from './pages/Home';
import NavStudio from './pages/NavStudio';
import FooterStudio from './pages/FooterStudio';
import GivingStudio from './pages/GivingStudio';
import GalleryStudio from './pages/GalleryStudio';
import Sermon from './pages/Sermon';
import AboutUs from './pages/AboutUs';
import { analytics } from './config/firebase-config';
import { logEvent, setUserId } from 'firebase/analytics';

const Routing = () => {

  logEvent(analytics, 'login', {
    content_id: 'HOME'
  });

  setUserId(analytics, "Home");

  return(
    <Router>
      <NavStudio/>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path='offering' element={<GivingStudio/>} />
        <Route exact path='sermon' element={<Sermon/>} />
        <Route exact path='gallery' element={<GalleryStudio/>} />
        <Route exact path='aboutus' element={<AboutUs/>} />
      </Routes>
      <FooterStudio/>
    </Router>
  )
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Routing/>
  </React.StrictMode>,
);
