import React from "react";

class ArticleStudio extends React.Component {
  render() {
    return (
      <section
        style={{
          padding: "48px 0px"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>
                  <span
                    style={{
                      color: "rgb(0, 0, 0)"
                    }}
                  >
                    Sunday Service
                  </span>
                </strong>
                <br />
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <strong>
                  <span
                    style={{
                      color: "rgb(0, 0, 0)"
                    }}
                  >
                    All Night
                  </span>
                </strong>
                <br />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-32 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  style={{
                    color: "rgb(255,0,0)"
                  }}
                >
                  {}
                  <path d="M152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 248H128V192H48V248zM48 296V360H128V296H48zM176 296V360H272V296H176zM320 296V360H400V296H320zM400 192H320V248H400V192zM400 408H320V464H384C392.8 464 400 456.8 400 448V408zM272 408H176V464H272V408zM128 408H48V448C48 456.8 55.16 464 64 464H128V408zM272 192H176V248H272V192z" />
                </svg>
                  
                <em>
                  <span
                    style={{
                      color: "rgb(102, 108, 114)"
                    }}
                  >
                    Sundays, 10:15 AM - 1:45 PM
                  </span>
                </em>
                <br />
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-64 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  style={{
                    color: "rgb(255,0,0)"
                  }}
                >
                  {}
                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                </svg>
                  
                <em>
                  <span
                    style={{
                      color: "rgb(207, 74, 70)"
                    }}
                  >
                    Prayer Line
                  </span>
                </em>
                <br />
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  style={{
                    color: "rgb(255,0,0)"
                  }}
                >
                  {}
                  <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                </svg>
                  
                <a href="tel:+1-425-4366391">
                  <em>
                    <span
                      style={{
                        color: "rgb(62, 109, 195)"
                      }}
                    >
                      4254366391 
                    </span>
                  </em>
                </a>
                <em>
                  <span
                    style={{
                      color: "rgb(102, 108, 114)"
                    }}
                  >
                    {" "}
                    -  Access Code: 513569#
                  </span>
                </em>
                <br />
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-32 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  style={{
                    color: "rgb(255,0,0)"
                  }}
                >
                  {}
                  <path d="M152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 248H128V192H48V248zM48 296V360H128V296H48zM176 296V360H272V296H176zM320 296V360H400V296H320zM400 192H320V248H400V192zM400 408H320V464H384C392.8 464 400 456.8 400 448V408zM272 408H176V464H272V408zM128 408H48V448C48 456.8 55.16 464 64 464H128V408zM272 192H176V248H272V192z" />
                </svg>
                  
                <em>
                  <span
                    style={{
                      color: "rgb(102, 108, 114)"
                    }}
                  >
                    Last Fridays of the month, 10:15 PM - 1:45 AM
                  </span>
                </em>
                <br />
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-64 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  style={{
                    color: "rgb(255,0,0)"
                  }}
                >
                  {}
                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                </svg>
                  
                <em>
                  <span
                    style={{
                      color: "rgb(207, 74, 70)"
                    }}
                  >
                    Prayer Line
                  </span>
                </em>
                <br />
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  style={{
                    color: "rgb(255,0,0)"
                  }}
                >
                  {}
                  <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                </svg>
                  
                <a href="tel:+1-425-4366391">
                  <em>
                    <span
                      style={{
                        color: "rgb(62, 109, 195)"
                      }}
                    >
                      4254366391 
                    </span>
                  </em>
                </a>
                <em>
                  <span
                    style={{
                      color: "rgb(102, 108, 114)"
                    }}
                  >
                     -  Access Code: 513569#
                  </span>
                </em>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{
            background: "rgba(146,64,64,0)"
          }}
        >
          <div
            className="text-white bg-dark border rounded border-0 p-4 p-md-5"
            style={{
              padding: "86px",
              height: "235.922px"
            }}
          >
            <h2 className="fw-bold text-white mb-3">Counselling</h2>
            <p className="mb-4">
              Sunday, 2:00 PM - 4:00 PM
              <br />
            </p>
            <div className="my-3">
              <a
                className="btn btn-primary btn-lg me-2"
                role="button"
                href="tel:+1-804-3260973"
              >
                Call Now
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ArticleStudio;
