import React from "react";
import cashapp from '../assets/img/cashapp.png';
import callin from '../assets/img/call-512.webp';
import paypal from '../assets/img/paypal.png';

class GivingStudio extends React.Component {
    render() {
        return (
            <section className="py-5 mt-5">
                <div className="container py-5">
                    <div className="row mb-4 mb-lg-5">
                        <div className="col-md-8 col-xl-6 text-center mx-auto">
                            <p className="fw-bold text-success mb-2">
                                <span
                                    style={{
                                        color: "rgb(202, 67, 54)"
                                    }}
                                >
                                    Giving
                                </span>
                            </p>
                            <h3 className="fw-bold">Tithes, Offerings, Seeds</h3>
                        </div>
                    </div>
                    <div
                        className="row row-cols-1 row-cols-md-2 mx-auto"
                        style={{
                            maxWidth: "900px"
                        }}
                    >
                        <div className="col mb-5">
                            <img
                                alt="cashapps"
                                className="rounded img-fluid shadow"
                                src={cashapp}
                            />
                        </div>
                        <div className="col d-md-flex align-items-md-end align-items-lg-center mb-5">
                            <div>
                                <h5 className="fw-bold">Give using CashApp</h5>
                                <a
                                    className="btn btn-primary shadow"
                                    role="button"
                                    href="http://cash.app/$thercc"
                                >
                                    CashApp
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row row-cols-1 row-cols-md-2 mx-auto"
                        style={{
                            maxWidth: "900px"
                        }}
                    >
                        <div className="col order-md-last mb-5">
                            <img
                                alt="calling"
                                className="rounded img-fluid shadow"
                                src={callin}
                            />
                        </div>
                        <div className="col d-md-flex align-items-md-end align-items-lg-center mb-5">
                            <div>
                                <h5 className="fw-bold">Give by Calling In</h5>
                                <a
                                    className="btn btn-primary shadow"
                                    role="button"
                                    href="tel:+1-203-7077475"
                                >
                                    Call In
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row row-cols-1 row-cols-md-2 mx-auto"
                        style={{
                            maxWidth: "900px"
                        }}
                    >
                        <div className="col mb-5">
                            <img
                                alt="payp"
                                className="rounded img-fluid shadow"
                                src={paypal}
                            />
                        </div>
                        <div className="col d-md-flex align-items-md-end align-items-lg-center mb-5">
                            <div>
                                <h5 className="fw-bold">Give using PayPal</h5>
                                <a 
                                href="https://www.paypal.com/cgi-bin/webscr?business=thercc2009%40gmail.com&cmd=_donations&item-name=Tithe%2C%20Seed%2C%20Offering&item-number=&currency_code=USD"
                                className="btn btn-primary shadow" type="button">
                                    PayPal
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default GivingStudio;
