import React, { useEffect } from "react";
import '../App.css';
//import logo from '../assets/img/thercc_logo.png'
import { analytics } from "../config/firebase-config";
import { logEvent, setUserId } from "firebase/analytics";

const trackPresentationInteraction = () => {
  analytics.logEvent('presentation_interaction', {
      action: 'click', // You can customize the action based on the user interaction
      component: 'sermon_presentation'
  });
};



const Sermon = () => {

  logEvent(analytics, 'login', {
    content_id: 'SERMON'
  });

  setUserId(analytics, "Sermon");


  useEffect(() => {
    const iframe = document.querySelector('iframe');
  
    const handleClick = () => {
        trackPresentationInteraction();
    };
  
    if (iframe) {
        iframe.contentWindow.addEventListener('click', handleClick);
    }
  
    return () => {
        if (iframe) {
            iframe.contentWindow.removeEventListener('click', handleClick);
        }
    };
  }, []);
    return (
      <div className="container">
        <div className="content bg-dark border rounded border-0 border-dark overflow-hidden">
          <div className="row g-0">
            <div className="large-screen col-md-12">
              <div className="text-white p-4 p-md-5">
                <h2 className="fw-bold text-white mb-3">
                  <strong>
                    <span style={{ backgroundColor: "rgba(52, 58, 64, 0)" }}>
                      Sermon
                    </span>
                  </strong>
                  <br></br>
                  <iframe title="march" src="https://docs.google.com/presentation/d/e/2PACX-1vQQXpj38ne__DfF9fXrkYdBJ7TI7Jd-QIifrQ1eqzC7g3uu192iXe_fa19tyN7T8M3MAAL-A8b7dWM0/embed?start=false&loop=false&delayms=3000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                  <br>
                  </br>
                </h2>
                <div className="my-3" />
              </div>
            </div>
          </div>
          {/* </div> */}

          {/* <div className="small-screen bg-dark border rounded border-0 border-dark overflow-hidden"> */}
          <div className="row g-0">
            <div className="small-screen col-md-12">
              <div className="text-white p-4 p-md-5">
                <h2 className="fw-bold text-white mb-3">
                  <strong>
                    <span style={{ backgroundColor: "rgba(52, 58, 64, 0)" }}>
                      Sermon
                    </span>
                  </strong>
                  <br></br>
                  <iframe title="march" src="https://docs.google.com/presentation/d/e/2PACX-1vQQXpj38ne__DfF9fXrkYdBJ7TI7Jd-QIifrQ1eqzC7g3uu192iXe_fa19tyN7T8M3MAAL-A8b7dWM0/embed?start=false&loop=false&delayms=3000" frameBorder="0" width="300" height="500" allowFullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                  <br>
                  </br>
                </h2>
                <div className="my-3" />
              </div>
            </div>
          </div>
        </div>
      </div>

      
    );
}

export default Sermon;