import React from "react";
import '../App.css';


const ContactStudio = () => {
    return (
      <section className="py-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-8 col-xl-6 text-center mx-auto">
              <p className="fw-bold text-danger mb-2">Contacts</p>
              <h2 className="fw-bold">How you can reach us</h2>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-6 col-xl-4">
              <div>
                <form action="https://formsubmit.co/b2d7cde7fb9347fccb114338f6837248" className="p-3 p-xl-4" method="post">
                  <div className="mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="name-1"
                      name="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      className="form-control"
                      type="email"
                      id="email-1"
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      id="message-1"
                      name="message"
                      rows={6}
                      placeholder="Message"
                      defaultValue={""}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary shadow d-block w-100"
                      type="submit"
                    >
                      Send{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4 col-xl-4 d-flex justify-content-center justify-content-xl-start">
              <div className="d-flex flex-wrap flex-md-column justify-content-md-start align-items-md-start h-100">
                <div className="d-flex align-items-center p-3">
                  <div className="bs-icon-md bs-icon-circle bs-icon-primary shadow d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon bs-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                    >
                      {}
                      <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                    </svg>
                  </div>
                  <div className="px-2">
                    <h6 className="fw-bold mb-0">Phone</h6>
                    <p className="text-muted mb-0">+1(804) 326-0973</p>
                  </div>
                </div>
                <div className="d-flex align-items-center p-3">
                  <div className="bs-icon-md bs-icon-circle bs-icon-primary shadow d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon bs-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      className="bi bi-envelope"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
                      />
                    </svg>
                  </div>
                  <div className="px-2">
                    <h6 className="fw-bold mb-0">Email</h6>
                    <p className="text-muted mb-0">christannointed@yahoo.com</p>
                    <p className="text-muted mb-0">contact@thercc.us</p>
                  </div>
                </div>
                <div className="d-flex align-items-center p-3">
                  <div className="bs-icon-md bs-icon-circle bs-icon-primary shadow d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon bs-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-64 0 512 512"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                    >
                      {}
                      <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                    </svg>
                  </div>
                  <div className="px-2">
                    <h6 className="fw-bold mb-0">Location</h6>
                    <p className="text-muted mb-0">
                      <span
                        style={{
                          backgroundColor: "transparent"
                        }}
                      >
                        4229 Park Ave. 
                      </span>
                      <br />
                      <span
                        style={{
                          backgroundColor: "transparent"
                        }}
                      >
                         Bronx, NY 10457
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default ContactStudio;
