import React, { useEffect } from 'react';
import '../App.css';
import HeaderStudio from './HeaderStudio';
import ArticleStudio from './ArticleStudio';
import ContactStudio from './ContactStudio';
import { analytics } from '../config/firebase-config';

const Home = () => {

  return (
    <>
      <HeaderStudio />
      <ArticleStudio />
      <ContactStudio />
    </>
  );
}

export default Home;
