import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics'

//console.firebase.google.com to get started
//Personal note remove other stuff
const firebaseConfig = {
  apiKey: "AIzaSyBM6IYqzLCnP3v5ZSCnOhrZchEtcF1V0ns",
  authDomain: "thercc-19b44.firebaseapp.com",
  databaseURL: "https://thercc-19b44.firebaseio.com",
  projectId: "thercc-19b44",
  storageBucket: "thercc-19b44.appspot.com",
  messagingSenderId: "794914358897",
  appId: "1:794914358897:web:380810115b3016df533dd0",
  measurementId: "G-QBGC36ZFQP"
};
  
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const provider = new GoogleAuthProvider();
