import React, { useEffect } from "react";
import logo from '../assets/img/thercc_logo.png'

const HeaderStudio = () => {

    return (
  <div className="container">
    <div className="bg-dark border rounded border-0 border-dark overflow-hidden">
      <div className="row g-0">
        <div className="col-md-6">
          <div className="text-white p-4 p-md-5">
            <h2 className="fw-bold text-white mb-3">
              <strong>
                <span style={{ backgroundColor: "rgba(52, 58, 64, 0)" }}>
                  WELCOME TO THE
                </span>
              </strong>
              <br />
              <strong>
                <span style={{ backgroundColor: "rgba(52, 58, 64, 0)" }}>
                  REDEEMER CHURCH
                </span>
              </strong>
              <br />
              <strong>
                <span style={{ backgroundColor: "rgba(52, 58, 64, 0)" }}>
                  OF CHRIST
                </span>
              </strong>
              <br />
            </h2>
            <p className="mb-4">
              Let the redeemed of the Lord say so, whom He has redeemed from the
              hand of the enemy
              <br />
              <br />
              -Psalm 107:2
            </p>
            <div className="my-3" />
          </div>
        </div>
        <div
          className="col-md-6 order-first order-md-last"
          style={{ minHeight: 250 }}
        >
          <img
            className="w-100 h-100 fit-cover"
            src={logo}
            style={{ transform: "scale(0.80)" }}
          />
        </div>
      </div>
    </div>
  </div>
    );


}

export default HeaderStudio;
