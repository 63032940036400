import React from "react";
import '../App.css';


const AboutUs = () => {
    return(
        <div>
      <h1>About Us</h1>
      <section>
        <h2>Mission</h2>
        <p>Our mission is to uphold and to defend the authoritative, the uncompromising and the unadulterated informative and the transformative word of God through the empowerment of the Holy Spirit at all times. The resources available to the church is to be channeled to develop these areas of our ministry:
1.Sustain congregational growth from graphic,geographic and demographic holistic view point.
2.To uphold an independent self sustaining programs that will help us mobilize resources effectively to make the vision and the set goals achievable.
3.The electronic and print media is part of our effective tools to address all factors that impede growth of our ministry.</p>
      </section>
      <section>
        <h2>Vision</h2>
        <p>At the Redeemer Church of Christ we follow God’s word - The bible as our sole guide in all matters of faith, life and practice. We seek to be Christian only to be free from denominational polity and man made tradition. (Matthew 16:18,Ephesians 1:22-23, Colossians 1:18 and Matthew 15:1-9).
Since our church is Christocentric we always seek to be evangelistic, disciple-making, undogmatic, united in faith and self –sustainable arithmetic and geometric growth oriented to meet the challenging technological and innovative contemporary world.</p>
      </section>
      <section>
        <h2>Core Values</h2>
        <p>
        The integrity, sanctity and sound doctrines is our hall mark. Since we are an immigrant growing church all ethnicity, colors, sexual orientations, genders, races, both the rich and the poor are equal in our ministry.
We always seek to maintain Discipline, a vibrant faith, accountability, transparency, ministry partnership, high standard leadership ethics, honesty, hard work, truth, justice and good human relations so that the churched,the unchurched and the de-churched will have sense of security and belonging at all time.If any person is looking for a place of truth,acceptance and mission we invite you to explore with us.
        </p>
      </section>
    </div>
    );
};

export default AboutUs;